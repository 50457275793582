<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="card-header p-0 pt-1">
                        <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                            <li class="pt-2 px-3"><h3 class="card-title">Kundenkonto: {{customer.customernumber}}</h3></li>
                            <li class="nav-item">
                                <a class="nav-link active" id="custom-tabs-two-data-tab" data-toggle="pill" href="#custom-tabs-two-data" role="tab" aria-controls="custom-tabs-two-data" aria-selected="true">Stammdaten</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="custom-tabs-two-addresses-tab" data-toggle="pill" href="#custom-tabs-two-addresses" role="tab" aria-controls="custom-tabs-two-addresses" aria-selected="false">Adressen</a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" id="custom-tabs-two-orders-tab" data-toggle="pill" href="#custom-tabs-two-orders" role="tab" aria-controls="custom-tabs-two-orders" aria-selected="false">Bestellungen</a>
                            </li> -->

                        </ul>
                    </div>

                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-two-tabContent">
                            <div class="tab-pane fade show active" id="custom-tabs-two-data" role="tabpanel" aria-labelledby="custom-tabs-two-data-tab">
                                <BaseData :customer="customer" v-if="notEmptyObject(customer)" />
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-two-addresses" role="tabpanel" aria-labelledby="custom-tabs-two-addresses-tab">
                                <Addresses :addresses="customer.addresses" :customer="customer" v-if="notEmptyObject(customer)"/>
                            </div>
                            <div class="tab-pane fade" id="custom-tabs-two-orders" role="tabpanel" aria-labelledby="custom-tabs-two-orders-tab">
                                <Orders />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import Addresses from './components/AddressesShow';
import BaseData from './components/BaseDataShow';
import Orders from './components/OrdersShow';


export default {
    name: "CustomersDetail",
    title: "Kunde bearbeiten",
    components:{
        Addresses,
        BaseData,
        Orders,
    },

    data() {
        return {
            customer: {},
        }
    },

    methods:{

        loadCustomer(){
            this.$Progress.start();
            this.axios
                .get("/customers/" + this.$route.params.id)
                .then((response) => {
                    this.customer = response.data.data;
                    if(this.customer.comment == null)
                    {
                        this.customer.comment = "";
                    }
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created(){
        this.loadCustomer();
    }

}
</script>

<style>

</style>